
import '../App.css';
import Button from '../components/Button';
import Header from '../components/Header';
import Card from '../components/namecard';
import { Images } from '../constants';
import './screen.css'
function HomePage() {
    return (
        <div >
            <div className='headerSection'>
                <Header />
            </div>
            {/* InterDuction */}
            <div className='bodySection'>
                <div className='contentsection'>
                    <p style={{
                        marginTop: "20%",
                        fontFamily: "League Spartan",
                        color: 'black',
                        fontSize: '20px',
                        lineHeight: '35px'
                    }}>
                        <p style={{
                            fontFamily: "League Spartan",
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: '30px'
                        }}>

                            Elevating Your Security Partnerships with AI-Driven Expertise
                        </p>
                        Our dynamic and pioneering platform poised to be the epicenter of the cybersecurity universe.
                        With a commitment to serving both the enterprises seeking impenetrable defenses and individuals seeking cybersecurity expertise,
                        bongu unites a diverse community of experts, ethical hackers, and seasoned professionals under one digital roof.
                        Join us on a journey through the evolving world of cybersecurity, where innovation and security converge.
                    </p>
                </div>
                <div className='imageSection'>
                    <img className='image' src={Images.bonguheader} alt="Logo" />
                </div>

            </div>
            <div style={{
                display: 'flex',
                width: '50%',
                justifyContent: 'space-around',
                flexWrap: 'wrap' // allows buttons to wrap in smaller screens
            }}>
                <Button label={"Consult an Expert"} />
                {/* <Button label={"Explore Opportunities"} /> */}
            </div>
            {/* Hire Experts */}
            <div className='bodySection'>
                <div className='imageSection2'>
                    <img className='image2' src={Images.bonguheader2} alt="Logo" />
                </div>
                <div className='contentsection'>
                    <p style={{
                        marginTop: "10%",
                        fontFamily: "League Spartan",
                        color: 'black',
                        fontSize: '20px',
                        lineHeight: '35px'
                    }}>
                        <p style={{
                            fontFamily: "League Spartan",
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: '30px'
                        }}>
                            Connecting You with Elite CyberExperts and Teams
                        </p>
                        In addition to connecting with experts, businesses can post cybersecurity-related job listings, projects, and consulting opportunities.
                        Cybersecurity professionals can browse these listings and apply for roles that align with their expertise.
                        <div style={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-around',
                            margin: '8%',
                            flexWrap: 'wrap' // allows buttons to wrap in smaller screens
                        }}>
                            <Button label={"Hire CyberExperts"} />
                        </div>
                    </p>
                </div>
            </div>
            {/* Explore Oppturnities */}
            <div className='bodySection'>
                <div className='contentsection'>
                    <p style={{
                        marginTop: "20%",
                        fontFamily: "League Spartan",
                        color: 'black',
                        fontSize: '20px',
                        lineHeight: '35px'
                    }}>
                        <p style={{
                            fontFamily: "League Spartan",
                            color: 'black',
                            fontWeight: 'bold',
                            fontSize: '30px'
                        }}>
                            Opportunity Hub
                        </p>
                        In addition to connecting with experts, businesses can post cybersecurity-related job listings, projects, and consulting opportunities.
                        Cybersecurity professionals can browse these listings and apply for roles that align with their expertise.
                        <div style={{
                            display: 'flex',
                            width: '50%',
                            margin: '8%',
                            justifyContent: 'space-around',
                            flexWrap: 'wrap' // allows buttons to wrap in smaller screens
                        }}>
                            <Button label={"Explore Opportunities"} />
                        </div>
                    </p>
                </div>
                <div className='imageSection'>
                    <img className='image' src={Images.bonguheader3} alt="Logo" />
                </div>
            </div>
        </div>
    );
}

export default HomePage;
