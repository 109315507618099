export const formSubmission = async (postData) => {
  const emailData = {
    to: "bongu.company@gmail.com",
    subject: postData.issueType,
    issueType: postData.issueType,
    description: postData.description,
    contactEmail: postData.contactEmail,
    phoneNumber: postData.phoneNumber,
    location: postData.location,
    callDateTime: postData.callDateTime
  };

  try {
    const response = await fetch('https://bongu-backend.onrender.com/email/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(emailData),
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log('Email sent successfully:', responseData);
      return responseData; // Return response data if needed
    } else {
      console.error('Email sending failed. HTTP status:', response.status);
      // Handle specific error cases here if necessary
    }
  } catch (error) {
    console.error('Error:', error);
    // Handle network or other errors here
  }
};
