import React, { useState } from 'react';
import FilterCard from '../components/filterCard';
import FlatList from '../components/FlatList';
import Header from '../components/Header';
import InDevelopment from '../components/Indevelopment';
import JobCardList from '../components/jobCardList';
import SearchComponent from '../components/SearchComponent';

const ExploreOppturnities = () => {
    const [onDevelopment, setonDevelopment] = useState(true)
    const cybersecurityJobs = [
        {
            id: "1",
            title: 'Cybersecurity Analyst',
            company: 'SecureGuard Solutions',
            location: 'Washington, D.C., USA',
            description: 'Join our team at SecureGuard Solutions and become a key player in safeguarding critical systems and data. As a Cybersecurity Analyst, you will work on threat detection, vulnerability assessments, and incident response to protect our clients from emerging cyber threats.',
            logoUrl: 'https://picsum.photos/200'
        },
        {
            id: "2",
            title: 'Penetration Tester',
            company: 'TechShield Cybersecurity',
            location: 'Los Angeles, USA',
            description: 'TechShield Cybersecurity is looking for a skilled Penetration Tester to identify vulnerabilities in our clients\' systems and applications. You will use your expertise to proactively secure their digital assets and provide valuable insights for enhancing their security posture.',
            logoUrl: 'https://picsum.photos/200'
        },
        {
            id: "3",
            title: 'Security Engineer',
            company: 'CyberSafe Innovations',
            location: 'Chicago, USA',
            description: 'At CyberSafe Innovations, we are on a mission to build secure systems and applications. As a Security Engineer, you will be at the forefront of designing, implementing, and maintaining security measures that protect our clients\' data and infrastructure.',
            logoUrl: 'https://picsum.photos/200'
        },
        {
            id: "4",
            title: 'Incident Responder',
            company: 'DefendNet Security',
            location: 'Boston, USA',
            description: 'DefendNet Security is seeking an Incident Responder to lead our incident handling and response efforts. In this role, you will analyze and mitigate security incidents, ensuring our clients are well-prepared to face cyber threats.',
            logoUrl: 'https://picsum.photos/200'
        },
        {
            id: "5",
            title: 'Cybersecurity Consultant',
            company: 'SecureTech Consulting',
            location: 'San Francisco, USA',
            description: 'SecureTech Consulting is dedicated to helping organizations implement robust security measures. As a Cybersecurity Consultant, you will work closely with clients to develop and maintain effective security strategies, ensuring their resilience against cyber threats.',
            logoUrl: 'https://picsum.photos/200'
        },
        // You can add more cybersecurity job objects with valuable descriptions as needed
    ];

    // Use the cybersecurityJobs array in your application





    return (
        <div>
            <div className='headerSection'>
                <Header label="HiringScreen" />
            </div>
            {
                onDevelopment ? <InDevelopment /> :

                    <>
                        <div style={{
                            display: 'flex',
                            width: "100%",
                            height: 100,
                            marginTop: 120,
                            marginLeft: "9%",
                            // marginLeft: "10%",
                            justifyContent: 'space-around',
                            alignItems: 'center'

                        }}>
                            <SearchComponent />
                        </div>
                        {/* Body Section */}
                        <div className='bodySectionFilter2'>
                            {

                            }
                            <div className='filterSection'>
                                <FilterCard height={300} items={{
                                    title: "Company type",
                                    filterCategories: ["Foreign MNC",
                                        "Coperate", "Indian MNC",
                                        "Start Up", "Goverment",
                                        "Others"]
                                }} />
                                <FilterCard height={300}
                                    items={{
                                        title: "Salary",
                                        filterCategories: ["0-3 Lakhs",
                                            "3-6 Lakhs", "6-10 Lakhs",
                                            "10-15 Lakhs", "above"]
                                    }}
                                />
                                <FilterCard height={300}
                                    items={{
                                        title: "Top companies",
                                        filterCategories: ["Wipro",
                                            "Accenture", "IBM",
                                        ]
                                    }}
                                />
                            </div>
                            <div className='listSecton2'>
                                <div>
                                    <JobCardList
                                        items={cybersecurityJobs}
                                    />

                                </div>
                            </div>
                        </div>
                    </>
            }
        </div>
    );
};

export default ExploreOppturnities;