import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Header from '../components/Header';
import { Images } from '../constants';
import { useNavigate } from 'react-router-dom';

const cardStyle = {
    width: 300,
    display: 'flex',
   margin: 15,
  
    height: 150,
    fontFamily: 'League Spartan',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'hsl(48, 26%, 93%)'
};

const spacingStyle = { marginBottom: '20px' };
const threats = [
  { id: 1, title: 'Social Media Recovery' },
  { id: 2, title: 'Call Frauds' },
  { id: 3, title: 'Phishing Attacks' },
  { id: 4, title: 'Identity Theft' },
  { id: 5, title: 'Malware Infections' },
  { id: 6, title: 'Email Scams' },
  { id: 7, title: 'Online Privacy Concerns' },
  { id: 8, title: 'Password Security' },
   { id: 9, title: 'Mobile Device Security' },
  // Add more threat objects as needed
];
const   ConsultingScreen = () => {
    const navigate = useNavigate();
    const handleRef = (param) => {
        // Do something when the card is clicked
        navigate(param);
    }
    return (
        <div>
            <div className="headerSection">
                <Header label="ConsultingScreen" />
            </div>
            <div className="bodySectionFilter5" style={{ display: 'flex',flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', height: '80vh', }}>
                 {threats.map((threat) => (
          <Card key={threat.id} onClick={() => handleRef(`/threatComplaint/${threat.id}`)} variant="elevation" elevation={4} style={cardStyle}>
            <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ color: 'black', alignSelf: 'center' }}>{threat.title}</p>
            </CardContent>
          </Card>
        ))}
            </div>
        </div>
    );
};

export default ConsultingScreen;
