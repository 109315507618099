import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SearchComponent = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [location, setLocation] = useState('');
    const [experience, setExperience] = useState('Any Experience');

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleLocationChange = (event) => {
        setLocation(event.target.value);
    };

    const handleExperienceChange = (event) => {
        setExperience(event.target.value);
    };

    const handleSearch = () => {
        console.log('Searching for:', searchTerm);
        console.log('Location:', location);
        console.log('Experience:', experience);
        // Implement your search logic here
    };

    return (
        <Card variant="elevation" elevation={6} style={{ borderRadius: 20 }}>
            <CardContent style={{ display: 'flex', alignItems: 'center', fontFamily:'League Spartan' }}>
            <TextField
              label={
                 <span style={{ fontFamily: 'League Spartan' }}>
                      What are you looking for?
                   </span>
                  }
                
             value={searchTerm}
             onChange={handleSearchChange}
             style={{ marginLeft: '10px', width: '100%', fontFamily: 'League Spartan' }}
             color="primary"
             fullWidth
             inputProps={{ style: { fontFamily: 'League Spartan' } }}
            />
                <Select
                    label="Experience"
                    value={experience}
                    onChange={handleExperienceChange}
                    style={{ marginLeft: '10px', width: '400px',
                     
                    fontFamily: "League Spartan",
                     }}
                >
                    <MenuItem style={{
                         fontFamily: "League Spartan",
                    }} value="Any Experience">Any Experience</MenuItem>
                    <MenuItem 
                     style={{
                        fontFamily: "League Spartan",
                   }} 
                    value="Entry Level">Entry Level</MenuItem>
                    <MenuItem
                     style={{
                        fontFamily: "League Spartan",
                   }} 
                    value="Intermediate">Intermediate</MenuItem>
                    <MenuItem
                     style={{
                        fontFamily: "League Spartan",
                   }} 
                    value="Advanced">Advanced</MenuItem>
                </Select>
                <TextField
                  label={
                  <span style={{ fontFamily: 'League Spartan' }}>
                     Location
                     </span>
                       }
                  variant="outlined"
                  value={location}
                  onChange={handleLocationChange}
                 style={{ marginRight: '10px', width: '50%', fontFamily: 'League Spartan', marginLeft: '10px' }}
                 color="primary"
                 fullWidth
                 inputProps={{ style: { fontFamily: 'League Spartan' } }}
                 />

                <IconButton color="primary" onClick={handleSearch}>
                <SearchIcon fontSize="large" />
                </IconButton>
            </CardContent>
        </Card>
    );
};

export default SearchComponent;
