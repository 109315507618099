import React from 'react';
import './component.css'
import { useNavigate } from 'react-router-dom';
const Button = ({ label }) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (label === "Hire CyberExperts") {
      navigate('/hiringDev');
    } else if (label === "Explore Opportunities") {
      navigate('/exploreOppturnities');
    } else if (label === "Consult an Expert") {
      navigate('/consultingScreen')
    }
  }
  return (
    <button className="custom-button" onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;