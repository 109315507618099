import React from 'react';
import { makeStyles } from '@mui/styles';
import Header from '../../components/Header';
import { FaEnvelope, FaLink } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EditIcon from '@mui/icons-material/Edit'; // Import the Edit icon

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
        alignItems: 'center',
      fontFamily: 'League Spartan',
    marginTop: '50px',
    gap: '32px', // Increased the gap for better separation
  },
  card: {
   alignSelf:'flex-start',
    padding: '16px',
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      fontFamily: 'League Spartan',
  },
  secondCard: {
    width: '750px', // Made the second card larger
    padding: '32px', // Increased padding for better spacing
      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
      fontFamily: 'League Spartan',
  },
  companyName: {
    fontSize: '14px',
      fontFamily: 'League Spartan',
  },
  contactInfo: {
    marginTop: '16px',
  },
  link: {
    textDecoration: 'none',
   // Added the primary color to links
  },
  socialLinks: {
    marginTop: '16px',
  },
  socialLink: {
    marginRight: '16px',
    textDecoration: 'none',
    // Added the primary color to links
  },
  section: {
    marginBottom: '16px',
    },
  editIcon: {
    marginLeft: '8px', // Add some spacing between the text and the edit icon
    cursor: 'pointer', // Make the icon look clickable
  },
}));

const CompanyProfiles = [
  // Replace with your actual company profile data
  {
    id: '1',
    companyName: 'Company Name',
    contactName: 'Contact Name',
    contactEmail: 'company@example.com',
    location: 'Company Location',
    website: 'www.example.com',
    description:
      'A leading company in the industry with a strong commitment to innovation and quality. We provide solutions that meet the needs of our clients worldwide.',
    socialLinks: {
      linkedin: 'https://www.linkedin.com/company/example',
      twitter: 'https://twitter.com/example',
      facebook: 'https://www.facebook.com/example',
    },
    specialties: ['Innovation', 'Quality Assurance', 'Global Reach'],
    awards: ['Best Company Award 2020', 'Innovation Excellence 2019'],
    additionalContent: {
      foundedYear: 1995,
      revenue: '$1 billion',
      employees: '5000+',
      services: [
        'Product Development',
        'Consulting',
        'Customer Support',
      ],
    },
  },
];

const CompanyProfile = () => {
  const classes = useStyles();

  const params = useParams();
  const { id } = params;
  const profileData = CompanyProfiles.find((profile) => profile.id === id);

  if (!profileData) {
    return <div>Company profile not found.</div>;
  }

  return (
    <div>
      <Header label="Company Profile" />
      <div className={classes.container}>
        <Card style={{  fontFamily: 'League Spartan',}} className={classes.card}>
          <CardContent>
            <div style={{display:'flex',justifyContent:'space-around',flexDirection:'row'}}>        
            <Typography variant="h5" className={classes.companyName}>
              {profileData.companyName}
                      </Typography>
                <EditIcon className={classes.editIcon} />
                 </div>
            <div className={classes.contactInfo}>
              <p>
                <FaEnvelope /> <strong>Email:</strong> {profileData.contactEmail}
              </p>
              <p>
                <FaLink /> <strong>Website:</strong>{' '}
                <Link href={profileData.website} target="_blank" rel="noopener noreferrer" className={classes.link}>
                  {profileData.website}
                </Link>
              </p>
              <p>
                <strong>Location:</strong> {profileData.location}
              </p>
              <p>
                <strong>Contact Name:</strong> {profileData.contactName}
              </p>
            </div>
          </CardContent>
        </Card>
        <Card  className={classes.secondCard}>
          <CardContent style={{  fontFamily: 'League Spartan',}} >
             <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom:'10px' }}>
               <Typography variant="h5" className={classes.companyName}>
              About Us
                </Typography>    
                  <EditIcon className={classes.editIcon} />
             </div>
                      
            <div className={classes.section}>
              <Typography>
                {profileData.description}
              </Typography>
            </div>
            <Typography variant="h5" className={classes.companyName}>
              Specialties
            </Typography>
            <div className={classes.section}>
              <Typography>
                {profileData.specialties.join(', ')}
              </Typography>
            </div>
            <Typography variant="h6" className={classes.companyName}>
              Awards
            </Typography>
            <div className={classes.section}>
              <Typography>
                {profileData.awards.join(', ')}
              </Typography>
            </div>
            <Typography variant="h5" className={classes.companyName}>
              Additional Information
            </Typography>
            <div className={classes.section}>
              <Typography>
                <strong>Founded Year:</strong> {profileData.additionalContent.foundedYear}
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography>
                <strong>Revenue:</strong> {profileData.additionalContent.revenue}
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography>
                <strong>Number of Employees:</strong> {profileData.additionalContent.employees}
              </Typography>
            </div>
            <div className={classes.section}>
              <Typography>
                <strong>Services:</strong> {profileData.additionalContent.services.join(', ')}
              </Typography>
            </div>
            <Typography variant="h5" className={classes.companyName}>
              Social Links
            </Typography>
            <div className={classes.section}>
              <Link href={profileData.socialLinks.linkedin} target="_blank" rel="noopener noreferrer" className={classes.socialLink}>
                <LinkedInIcon className={classes.socialIcon} />
                LinkedIn
              </Link>
              <Link href={profileData.socialLinks.twitter} target="_blank" rel="noopener noreferrer" className={classes.socialLink}>
                <TwitterIcon className={classes.socialIcon} />
                Twitter
              </Link>
              <Link href={profileData.socialLinks.facebook} target="_blank" rel="noopener noreferrer" className={classes.socialLink}>
                <FacebookIcon className={classes.socialIcon} />
                Facebook
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CompanyProfile;
