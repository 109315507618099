import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './datepicker-styles.css';
import { addMinutes, format } from 'date-fns';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { formSubmission } from '../../api';

const threats = [
  { id: "1", title: 'Social Media Recovery' },
  { id: "2", title: 'Call Frauds' },
  { id: "3", title: 'Phishing Attacks' },
  { id: "4", title: 'Identity Theft' },
  { id: "5", title: 'Malware Infections' },
  { id: "6", title: 'Email Scams' },
  { id: "7", title: 'Online Privacy Concerns' },
  { id: "8", title: 'Password Security' },
  { id: "9", title: 'Mobile Device Security' },
  // Add more threat objects as needed
];

const ThreatComplaint = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const now = new Date();
  const defaultDateTime = addMinutes(now, 15);
  const defaultDateTimeFormatted = format(defaultDateTime, "MMMM d, yyyy h:mm aa");
  const issueTypeSelected = threats.filter((item) => item.id === id)[0].title;
  const options = threats.map((item) => item.title);

  const [formData, setFormData] = useState({
    issueType: issueTypeSelected,
    description: '',
    contactEmail: '',
    phoneNumber: '', // Added phone number field
    severity: '',
    location: '',
    callDateTime: defaultDateTime,
  });

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateTimeChange = (value) => {
    setFormData({
      ...formData,
      callDateTime: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission here, e.g., send the data to an API
    let PostForm = await formSubmission(formData);
    // Show the success message
    setShowSuccessMessage(true);
  };

  return (
    <div>
      <div className="headerSection">
        <Header label="ProfileCreation" />
      </div>
      <div style={{marginTop:100, padding: isMobile ? 10 :100}}>
        <Card style={{ maxWidth: '100%', margin: '0 auto', fontFamily: 'League Spartan', marginTop: '10px',}}>
          <CardContent>
            {!showSuccessMessage ? (
              <form  onSubmit={handleSubmit}>
                <h2>Report a Hacking Issue</h2>
                <div style={{ marginBottom: '20px' }}>
                  <Autocomplete
                    id="issue-type"
                    value={issueTypeSelected}
                    options={options}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Issue Type"
                        name="issueType"
                        onChange={handleInputChange}
                        required
                      />
                    )}
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    label="Description"
                    name="description"
                    multiline
                    rows={4}
                    onChange={handleInputChange}
                    required
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    label="Contact Email"
                    name="contactEmail"
                    type="email"
                    onChange={handleInputChange}
                    required
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    label="Phone Number"
                    name="phoneNumber"
                    type="tel"
                    onChange={handleInputChange}
                    required
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: '20px' }}>
                  <TextField
                    label="Location"
                    name="location"
                    onChange={handleInputChange}
                    required
                    fullWidth
                  />
                </div>
                <div style={{ marginBottom: '20px', width: '100%', display: 'flex', flexDirection: 'column' }}>
                  <label>Select Date</label>
                  <DatePicker
                    selected={formData.callDateTime}
                    onChange={handleDateTimeChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="form-control"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px', justifyContent: 'center' }}>
                  <Button variant="contained" color="primary" type="submit">
                    Book a Call
                  </Button>
                </div>
              </form>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                <div>
                  <h2 style={{ color: 'green' }}>Success</h2>
                  <p>Your submission was successful! We will contact you soon.</p>
                </div>
                <div style={{ marginLeft: 100 }}>
                  <ThumbUpIcon color="success" fontSize="large" />
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ThreatComplaint;
