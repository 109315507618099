import React, { useState, useRef } from 'react';

const ImageCarousel = () => {
    const images = [
        'https://source.unsplash.com/random/400x200',
        'https://source.unsplash.com/random/400x201',
        'https://source.unsplash.com/random/400x202',
        'https://source.unsplash.com/random/400x202',
        'https://source.unsplash.com/random/400x202',
        // Add more image URLs as needed
    ];

    const [scrollPosition, setScrollPosition] = useState(0);
    const cardListRef = useRef(null);

    const handleScroll = (direction) => {
        const cardList = cardListRef.current;
        const cardWidth = cardList.querySelector('.card').offsetWidth;
        const scrollAmount = direction === 'left' ? -cardWidth : cardWidth;
        cardList.scrollLeft += scrollAmount;
        setScrollPosition(cardList.scrollLeft);
    };

    return (
        <div style={{ width: '100%' , height:"100%" }}>
            <div className="carousel-container">
                <button className="nav-button left" onClick={() => handleScroll('left')}>&lt;</button>
                <div className="horizontal-card-list" ref={cardListRef}>
                    {images.map((imageUrl, index) => (
                        <div key={index} className="card">
                            <img src={imageUrl} alt={`Image ${index}`} />
                        </div>
                    ))}
                </div>
                <button className="nav-button right" onClick={() => handleScroll('right')}>&gt;</button>
            </div>
        </div>
    );
};

export default ImageCarousel;
