import React from 'react';
import './component.css';
import { FaEnvelope, FaLinkedin, FaStar } from 'react-icons/fa';
import { createSearchParams, useNavigate } from 'react-router-dom';
// SkillBubble component to display a skill as a bubble
const SkillBubble = ({ skill }) => {
  return (
    <div className="skill-bubble">
      {skill}
    </div>
  );
};

const ProfileCard = ({ item }) => {
  const { name, occupation, profileImage, email, linkedin, bio, location, skills, rating } = item;
  const navigate = useNavigate();
  const handleCardClick = () => {
    // Do something when the card is clicked
    navigate(`/profile/${item.id}`);
    console.log('Card clicked for:', item.name);
  };

  const renderRatingStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <FaStar
          key={i}
          className={`star-icon ${i < Math.floor(rating) ? 'filled' : ''}`}
        />
      );
    }
    return stars;
  };

  return (
    <div  className="profile-card" onClick={handleCardClick}>
      <div className="profile-card-header">
        <img src={profileImage} alt={`Profile of ${name}`} className="profile-image" />
        <div>
          <h2 className="profile-name">{name}</h2>
          <p className="profile-occupation">{occupation}</p>
        </div>
      </div>
      <div className="profile-card-content">
        <div className="profile-info">
          <p><FaEnvelope /> <strong>Email:</strong> {email}</p>
          <p><FaLinkedin /> <strong>LinkedIn:</strong> <a href={linkedin} target="_blank" rel="noopener noreferrer">{name}'s LinkedIn Profile</a></p>
          <p><strong>Bio:</strong> {bio}</p>
          <p><strong>Location:</strong> {location}</p>
          <div className="skills-section">
            <strong>Skills:</strong>
            <div className="skill-bubbles">
              {skills.map((skill, index) => (
                <SkillBubble key={index} skill={skill} />
              ))}
            </div>
          </div>
          <div className="rating-section">
            <strong>Rating:</strong>
            <div className="rating-stars">
              {renderRatingStars()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;