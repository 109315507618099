import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Header from '../../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEnvelope, FaLinkedin } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: '100%',
    margin: '0 auto',
    marginTop: '100px',
    height: '70%',
    width: '60%',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    width: '60%',
    justifyContent: 'space-between',
    marginTop: '20px',
    alignSelf: 'flex-end',
  },
  fileInput: {
    display: 'none',
  },
}));
const CyberExperts = [
  {
      id: "1",
      name: "David",
      occupation: "Ethical Hacker",
      profileImage: "https://source.unsplash.com/random/200x200",
      email: "david@example.com",
      linkedin: "https://www.linkedin.com/in/david/",
      bio: "Experienced ethical hacker with a passion for cybersecurity and data protection.",
      location: "New York, USA",
      skills: ["Penetration Testing", "Web Application Security", "Network Security"],
      rating: 4.8,
      certifications: ["CEH", "OSCP", "CISSP", "CISM", "GIAC"],
      Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
      experience:{
          Title : "Cybersecurity and Data Protection Expert",
          Discription: "With over 14 years of experience in the Cybersecurity industry, I have a proven track record of success in both hands-on and managerial roles. I am passionate about creating a safer world where businesses are protected from Cybercriminals, and I have worked tirelessly to make that dream a reality. My expertise has been recognized through the approval of six patents in the United States, and I am always seeking new ways to innovate and improve upon existing solutions. I am committed to helping businesses protect themselves and their customers from the ever-evolving threat of Cyberattacks."
      }
  },
]

const OwnProfileScreen = () => {
  const params = useParams();
  const { id } = params
    const navigate = useNavigate();
    let profileData = CyberExperts.filter((item) => item.id === id)[0];
  return (
    <div>
      <div className='headerSection'>
        <Header label='ProfileCreation' />
      </div>
      <div className='bodySectionFilter'>
                <div className='profileSection'>
                    <div class="profileImage">
                    <img src={profileData.profileImage} alt="Profile Image" />
                        <div className="profile-info">
                            <h2 className="profile-name">{profileData.name}</h2>
                            <p><FaEnvelope /> <strong>Email:</strong> {profileData.email}</p>
                            <p><FaLinkedin /> <strong>LinkedIn:</strong> <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer">{profileData.name}'s LinkedIn Profile</a></p>
                            <p><strong>Bio:</strong> {profileData.bio}</p>
                            <p><strong>Location:</strong> {profileData.location}</p>
                        </div>
                    </div>
                    <div className='profileImage'>
                        <div className="skills-section">
                            <strong>Skills :</strong>
                        </div>
                        {<br></br>}
                        <div style={{
                            paddingTop: 10
                        }}>
                            <strong>Rating :</strong>
                            <div className="rating-stars">
                            </div>
                        </div>
                        {<br></br>}
                        <strong>Education Level :</strong>
                        <div className="skill-bubbles">
                          
                        </div>
                    </div>
                    <div class="profileImage">
                        <strong>Certification :</strong>
                        <div className="skill-bubbles">
                           
                        </div>
                        {<br></br>}
                        <strong>Programing Languages :</strong>
                        <div className="skill-bubbles">
                           
                        </div>
                    </div>

                </div>
                <div className='discriptionSection'>
                
                    <div className='discriptionText'>
                       
                    </div>
                    <div className='skillscontainer'>
                      
                        <div className='buttonsContainer'>
                            <div className='buttons'>
                         
                            </div>
                            <div className='buttons'>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  );
};

export default OwnProfileScreen;
