import { Card, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Header from '../components/Header';
import { makeStyles } from '@mui/styles';
import './screen.css'
import { useParams } from 'react-router-dom';
import { GrLocation } from 'react-icons/gr';
import { FiBriefcase } from "react-icons/fi";


const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%', // Make the card narrower
        padding: '16px', // Reduce padding for a smaller appearance
        margin: '20px',
        borderRadius: '70px', // Add more rounded corners
        transition: 'transform 0.2s', // Add a transition effect
        '&:hover': {
            transform: 'scale(1.02)', // Scale up on hover
        },
    },
    content: {
        flex: 1,
    },
    title: {
        marginBottom: '4px',
        color: 'black',
    },
    company: {
        marginBottom: '4px',
        color: 'black',
    },
    location: {
        fontSize: '0.9rem',
        color: '#555',
        marginBottom: '20px',
    },
    description: {
        fontSize: '0.9rem',
        color: '#333',
    },
    applyButton: {
        marginLeft: 'auto',
        background: '#00a82d',
        color: '#fff',
        '&:hover': {
            background: '#009925',
        },
    },
    logo: {
        width: '70px',
        height: '70px',
        borderRadius: '40px',
        alignSelf: 'flex-end'
    },
});
const cybersecurityJobs = [
    {
        id: "1",
        title: 'Cybersecurity Analyst',
        company: 'SecureGuard Solutions',
        location: 'Washington, D.C., USA',
        description: 'Join our team at SecureGuard Solutions and become a key player in safeguarding critical systems and data. As a Cybersecurity Analyst, you will work on threat detection, vulnerability assessments, and incident response to protect our clients from emerging cyber threats.',
        logoUrl: 'https://picsum.photos/200',
        posted: "3",
        applications: '25',
        highLights: [
            "Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field",
            "6+ years of experience in Java development", "Strong experience of Java and related technologies,including SpringBoot,Microservices,Hibernate,and RESTful web services",
            "Experience with agile software development methodologies"
        ],
        resposibities: [
            'Participation in the development and implementation of security and authorization concepts as well as hardening guidelines.',
            'Design and implementation of IT security monitoring and IT security management systems.',
            'Information gathering about threat scenarios and corresponding proactive communication',
            'Continuous improvement of central IT security, among other things by monitoring and conducting penetration tests and audits.'
        ],
        skills: ["Penetration Testing", "Web Application Security", "Network Security"],
    },
    {
        id: "2",
        title: 'Penetration Tester',
        company: 'TechShield Cybersecurity',
        location: 'Los Angeles, USA',
        description: 'TechShield Cybersecurity is looking for a skilled Penetration Tester to identify vulnerabilities in our clients\' systems and applications. You will use your expertise to proactively secure their digital assets and provide valuable insights for enhancing their security posture.',
        logoUrl: 'https://picsum.photos/200',
        posted: "21",
        applications: '25',
        highLights: [
            "Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field",
            "6+ years of experience in Java development", "Strong experience of Java and related technologies,including SpringBoot,Microservices,Hibernate,and RESTful web services",
            "Experience with agile software development methodologies"
        ],
        resposibities: [
            'Participation in the development and implementation of security and authorization concepts as well as hardening guidelines.',
            'Design and implementation of IT security monitoring and IT security management systems.',
            'Information gathering about threat scenarios and corresponding proactive communication',
            'Continuous improvement of central IT security, among other things by monitoring and conducting penetration tests and audits.'
        ],
        skills: ["Penetration Testing", "Web Application Security", "Network Security"],
    },
    {
        id: "3",
        title: 'Security Engineer',
        company: 'CyberSafe Innovations',
        location: 'Chicago, USA',
        description: 'At CyberSafe Innovations, we are on a mission to build secure systems and applications. As a Security Engineer, you will be at the forefront of designing, implementing, and maintaining security measures that protect our clients\' data and infrastructure.',
        logoUrl: 'https://picsum.photos/200',
        posted: "91",
        applications: '25',
        highLights: [
            "Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field",
            "6+ years of experience in Java development", "Strong experience of Java and related technologies,including SpringBoot,Microservices,Hibernate,and RESTful web services",
            "Experience with agile software development methodologies"
        ],
        resposibities: [
            'Participation in the development and implementation of security and authorization concepts as well as hardening guidelines.',
            'Design and implementation of IT security monitoring and IT security management systems.',
            'Information gathering about threat scenarios and corresponding proactive communication',
            'Continuous improvement of central IT security, among other things by monitoring and conducting penetration tests and audits.'
        ],
        skills: ["Penetration Testing", "Web Application Security", "Network Security"],
    },
    {
        id: "4",
        title: 'Incident Responder',
        company: 'DefendNet Security',
        location: 'Boston, USA',
        description: 'DefendNet Security is seeking an Incident Responder to lead our incident handling and response efforts. In this role, you will analyze and mitigate security incidents, ensuring our clients are well-prepared to face cyber threats.',
        logoUrl: 'https://picsum.photos/200',
        posted: "5",
        applications: '25',
        highLights: [
            "Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field",
            "6+ years of experience in Java development", "Strong experience of Java and related technologies,including SpringBoot,Microservices,Hibernate,and RESTful web services",
            "Experience with agile software development methodologies"
        ],
        resposibities: [
            'Participation in the development and implementation of security and authorization concepts as well as hardening guidelines.',
            'Design and implementation of IT security monitoring and IT security management systems.',
            'Information gathering about threat scenarios and corresponding proactive communication',
            'Continuous improvement of central IT security, among other things by monitoring and conducting penetration tests and audits.'
        ],
        skills: ["Penetration Testing", "Web Application Security", "Network Security"],
    },
    {
        id: "5",
        title: 'Cybersecurity Consultant',
        company: 'SecureTech Consulting',
        location: 'San Francisco, USA',
        description: 'SecureTech Consulting is dedicated to helping organizations implement robust security measures. As a Cybersecurity Consultant, you will work closely with clients to develop and maintain effective security strategies, ensuring their resilience against cyber threats.',
        logoUrl: 'https://picsum.photos/200',
        posted: "4",
        applications: '25',
        highLights: [
            "Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field",
            "6+ years of experience in Java development", "Strong experience of Java and related technologies,including SpringBoot,Microservices,Hibernate,and RESTful web services",
            "Experience with agile software development methodologies"
        ],
        resposibities: [
            'Participation in the development and implementation of security and authorization concepts as well as hardening guidelines.',
            'Design and implementation of IT security monitoring and IT security management systems.',
            'Information gathering about threat scenarios and corresponding proactive communication',
            'Continuous improvement of central IT security, among other things by monitoring and conducting penetration tests and audits.'
        ],
        skills: ["Penetration Testing", "Web Application Security", "Network Security"],
    },
    // You can add more cybersecurity job objects with valuable descriptions as needed
];

const JobDiscription = (props) => {
    const classes = useStyles();
    const params = useParams();
    const { id } = params
    const { title, company, location, description, logoUrl, posted, applications, highLights, resposibities, skills } = cybersecurityJobs.filter((item) => item?.id === id)[0];;


    const SkillBubble = ({ skill }) => {
        return (
            <div className="skill-bubble"   >
                {skill}
            </div>
        );
    };
    return (
        <div className="profile-screen-container">
            <div className='headerSection'>
                <Header label="HiringScreen" />
            </div>

            {/* Body Section */}
            <div className='jobContainer'>

                <div className='bodySectionFilter3'>
                    <div style={{ width: "100%", display: 'flex', justifyContent: 'row', justifyContent: 'center' }}>
                        {/* Basic Discription */}
                        <Card variant="elevation" style={{ width: '32rem', display: 'flex', flexDirection: 'column', }} elevation={4} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '2%', marginLeft: '4%', marginRight: '4%', marginTop: '2%' }}>
                                <div >
                                    <Typography style={{
                                        fontSize: 22,
                                        fontFamily: "League Spartan", fontWeight: 'bold',

                                    }} className={classes.title}>
                                        {title}
                                    </Typography>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                        <FiBriefcase size={15} />
                                        <Typography
                                            style={{
                                                fontSize: 14,
                                                fontFamily: "League Spartan", fontWeight: 'bold',
                                                marginTop: '3%',
                                                marginLeft: '2%'
                                            }}
                                            className={classes.location}>

                                            {company}
                                        </Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <GrLocation size={15} />
                                        <Typography
                                            style={{
                                                fontSize: 14,
                                                fontFamily: "League Spartan", fontWeight: 'bold',
                                                marginTop: '3%',
                                                marginLeft: '2%'
                                            }}
                                            className={classes.location}>
                                            {location}
                                        </Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: 230, justifyContent: 'space-between' }}>
                                        <p style={{
                                            marginLeft: '2%', fontSize: 14,
                                            fontFamily: "League Spartan", fontWeight: 'bold',
                                        }}>Posted : {posted} days ago | Application : {applications}</p>
                                    </div>
                                </div>
                                <div style={{ alignSelf: 'flex-end', display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexDirection: 'column' }}>
                                    <img src={logoUrl} alt="Company Logo" className={classes.logo} />
                                    <div style={{ display: 'flex', flexDirection: 'row', }}>
                                        <button
                                            style={{
                                                width: 120,
                                                height: 30,
                                                backgroundColor: 'black',
                                                borderRadius: 20,
                                                marginTop: "15%",
                                                marginRight: "10%",
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: 'none', // Optional: Remove the default button border
                                                cursor: 'pointer', // Optional: Add a pointer cursor on hover
                                            }}
                                        >
                                            <p style={{ color: 'hsl(48, 26%, 93%)' }}>Apply</p>
                                        </button>
                                        <button
                                            style={{
                                                width: 120,
                                                height: 30,
                                                backgroundColor: 'black',
                                                borderRadius: 20,
                                                marginTop: "15%",
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                border: 'none', // Optional: Remove the default button border
                                                cursor: 'pointer', // Optional: Add a pointer cursor on hover
                                            }}
                                        >
                                            <p style={{ color: 'hsl(48, 26%, 93%)' }}>Email Us</p>
                                        </button>
                                    </div>
                                </div>

                            </div>

                        </Card>

                    </div>
                    {/* Detailed Job Discription */}
                    <Card variant="elevation" style={{ display: 'flex', width: '32rem', marginTop: "2%", flexDirection: 'row' }} elevation={4} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            marginBottom: '2%',
                            marginLeft: '4%',
                            marginRight: '4%',
                            marginTop: '5%',
                        }}>

                            <div>
                                <Typography style={{
                                    // fontSize: 22,
                                    fontFamily: "League Spartan",
                                    fontWeight: 'bold',
                                }} >
                                    Job Highlights
                                    <ul style={{}}>
                                        {highLights.map((item, index) => (
                                            <li key={index}>
                                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                                    {item}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </Typography>
                                <Typography style={{
                                    fontFamily: "League Spartan",
                                    fontWeight: 'bold',
                                }} className={classes.title}>
                                    Job description
                                    <ul style={{}}>
                                        {resposibities?.map((item, index) => (
                                            <li key={index}>
                                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                                    {item}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </Typography>
                                <Typography style={{
                                    fontFamily: "League Spartan",
                                    fontWeight: 'bold',
                                }} className={classes.title}>
                                    Job description
                                    <ul style={{}}>
                                        {resposibities?.map((item, index) => (
                                            <li key={index}>
                                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                                    {item}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </Typography>
                            </div>
                        </div>
                    </Card>

                    {/* About Company Discription */}
                    <Card variant="elevation" style={{ width: '32rem', display: 'flex', marginTop: "2%", marginBottom: '2%' }} elevation={4} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            marginBottom: '4%',
                            marginLeft: '4%',
                            marginRight: '4%',
                            marginTop: '5%',
                        }}>
                            <Typography style={{
                                // fontSize: 22,
                                fontFamily: "League Spartan",
                                fontWeight: 'bold',
                            }} >Key Skills
                                <div style={{ display: 'flex', flexdirection: 'row', justifyContent: 'space-evenly' }}>
                                    {skills.map((skill, index) => (
                                        <SkillBubble key={index} skill={skill} />
                                    ))}
                                </div>
                            </Typography>
                        </div>
                    </Card>
                </div>
                <div className='bodySectionFilter4'>
                    <Card variant="elevation" style={{ width: "100%", height: "30%", display: 'flex', flexDirection: 'column', }} elevation={4} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            marginBottom: '2%',
                            marginLeft: '7%',
                            marginRight: '4%',
                            marginTop: '10%',
                        }}>
                            <Typography style={{
                                // fontSize: 22,
                                fontFamily: "League Spartan",
                                fontWeight: 'bold',
                            }} >Reviews
                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                    {"Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field"}
                                </p>
                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                    {"Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field"}
                                </p>
                            </Typography>
                        </div>

                    </Card>
                    <Card variant="elevation" style={{ width: "100%", height: "30%", display: 'flex', flexDirection: 'column', marginTop: '3%' }} elevation={4} >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            marginBottom: '2%',
                            marginLeft: '7%',
                            marginRight: '4%',
                            marginTop: '10%',
                        }}>
                            <Typography style={{
                                // fontSize: 22,
                                fontFamily: "League Spartan",
                                fontWeight: 'bold',
                            }} >Reviews
                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                    {"Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field"}
                                </p>
                                <p style={{ fontSize: 14, fontFamily: "League Spartan", fontWeight: 'lighter' }}>
                                    {"Requirements: . Bachelors or Masters degree in Computer Science,Software Engineering or related field"}
                                </p>
                            </Typography>
                        </div>
                    </Card>
                </div>
            </div>

        </div>
    );
};

export default JobDiscription;