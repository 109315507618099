import React from 'react';
import './component.css';

function InDevelopment(props) {
  const items = props.items; // An array of items to display

  return (
    <div className="centered-content">
      <h1 className="development-text">Under Development</h1>
      <p className="subtext">This page is currently under development.</p>
      {/* Additional content or messages go here */}
    </div>
  );
}

export default InDevelopment;
