import React from 'react';
import ProfileCard from './profileCard';
import './component.css'
function FlatList(props) {
  const items = props.items; // An array of items to display

  return (
    <div className="flat-list">
      {items.map((item, index) => (
        <ProfileCard key={index} item={item} />
      ))}
    </div>
  );
}

export default FlatList;