import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';

const buttonStyle = { margin: '1.8%', borderRadius: 6, fontFamily: 'League Spartan', color: 'black', backgroundColor: 'hsl(48, 26%, 93%)' };
const textFieldStyle = { margin: '1.8%', borderRadius: 6, fontFamily: 'League Spartan', width: '100%' };
const cardLabelStyle = { fontFamily: 'League Spartan' };

const CompanyRegistration = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        companyName: '',
        industry: '',
        contactName: '',
        contactEmail: '',
        location: '',
        taxId: '',
        website: '',
        employeeCount: '',
    });
    const [currentStep, setCurrentStep] = useState(1);

    const steps = [
        {
            label: 'Step 1: Company Information',
            fields: ['companyName', 'industry', 'contactName'],
        },
        {
            label: 'Step 2: Contact Information',
            fields: ['contactEmail', 'location', 'taxId'],
        },
        {
            label: 'Step 3: Additional Details',
            fields: ['website', 'employeeCount'],
        }
    ]
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleNext = () => {
        if (currentStep < steps.length) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handleBack = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    const isLastStep = currentStep === steps.length;

    return (
        <div>
            <div className="headerSection">
                <Header label="Company Registration" />
            </div>
            <div className="bodySectionFilter5">
                <Card style={{ maxWidth: '100%', margin: '0 auto', fontFamily: 'League Spartan', marginTop: '10px', width: '50%', }}>
                    <CardContent>
                        <h2>Register Your Company</h2>
                        <form>
                            <h3 style={cardLabelStyle}>{steps[currentStep - 1].label}</h3>
                            {steps[currentStep - 1].fields.map((field) => (
                                <TextField
                                    key={field}
                                    label={field}
                                    name={field}
                                    value={formData[field]}
                                    onChange={handleChange}
                                    fullWidth
                                    style={textFieldStyle}
                                    required
                                    InputLabelProps={
                                        { style: { fontFamily: 'League Spartan' } }
                                    }
                                // style={{ ...textFieldStyle, label: { ...cardLabelStyle } }}
                                />
                            ))}
                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: '10%', justifyContent: 'space-evenly' }} variant="elevation" elevation={4}>
                                <Button
                                    onClick={handleBack}
                                    variant="contained"
                                    disabled={currentStep === 1}
                                    style={buttonStyle}
                                >
                                    Back
                                </Button>
                                <Button
                                    onClick={isLastStep ? handleSubmit : handleNext}
                                    variant="contained"
                                    style={buttonStyle}
                                >
                                    {isLastStep ? 'Submit' : 'Next'}
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    );

    function handleSubmit() {
        // Handle the final form submission, e.g., send data to an API or save it locally
        console.log(formData);
        navigate('/companyProfileScreen/1');
    }
};

export default CompanyRegistration;
