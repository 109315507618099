import React from 'react';
import './component.css';
import Checkbox from '@mui/material/Checkbox';
function FilterCard(props) {
    const { height, items } = props;
    const title = items?.title;
    const filterCategories = items?.filterCategories;

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    return (
        <div style={{ height: height }} className="card">
            <div className="card-content">
                <h3 style={{ textAlign:'left',
                                fontFamily: "League Spartan", }}>{title}</h3>
                <ul>
                    {filterCategories?.map((category, index) => (
                        <div key={index} >
                            <label style={{
                                fontFamily: "League Spartan",
                            }} className="checkbox-label" htmlFor={category}>
                                <Checkbox  {...label} />
                                {category}
                                <span className="custom-checkbox"></span>
                            </label>
                        </div>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default FilterCard;