import React, { useState } from 'react';
import FilterCard from '../components/filterCard';
import FlatList from '../components/FlatList';
import Header from '../components/Header';
import InDevelopment from '../components/Indevelopment';
import ProfileCard from '../components/profileCard';
import MultiSliderCarousel from '../components/SwipeableList';
import './screen.css'
const HiringDev = ({ label, onClick }) => {
  const [onDevelopment, setonDevelopment] = useState(true)
  const CyberExperts = [
    {
      id: 1,
      name: "David",
      occupation: "Ethical Hacker",
      profileImage: "https://source.unsplash.com/random/200x200",
      email: "david@example.com",
      linkedin: "https://www.linkedin.com/in/david/",
      bio: "Experienced ethical hacker with a passion for cybersecurity and data protection.",
      location: "New York, USA",
      skills: ["Penetration Testing", "Web Application Security", "Network Security"],
      rating: 4.8
    },
    {
      id: 2,
      name: "Sarah",
      occupation: "Penetration Tester",
      profileImage: "https://source.unsplash.com/random/200x200",
      email: "sarah@example.com",
      linkedin: "https://www.linkedin.com/in/sarah/",
      bio: "Specializing in penetration testing and identifying vulnerabilities in systems.",
      location: "London, UK",
      skills: ["Vulnerability Assessment", "Social Engineering", "Wireless Security"],
      rating: 3.6
    },
    {
      id: 3,
      name: "Michael",
      occupation: "Security Analyst",
      profileImage: "https://picsum.photos/200/302",
      email: "michael@example.com",
      linkedin: "https://www.linkedin.com/in/michael/",
      bio: "Dedicated security analyst with a focus on threat detection and incident response.",
      location: "San Francisco, USA",
      skills: ["Security Monitoring", "Incident Response", "Malware Analysis"],
      rating: 4.9
    },
    {
      id: 4,
      name: "Emily",
      occupation: "Cybersecurity Consultant",
      profileImage: "https://picsum.photos/200/303",
      email: "emily@example.com",
      linkedin: "https://www.linkedin.com/in/emily/",
      bio: "Cybersecurity consultant assisting organizations in implementing robust security measures.",
      location: "Toronto, Canada",
      skills: ["Security Strategy", "Compliance Management", "Security Awareness"],
      rating: 2.7
    },
    // Add more experts here
    {
      id: 4,
      name: "Tanya",
      occupation: "Cybersecurity Researcher",
      profileImage: "https://picsum.photos/200/303",
      email: "emily@example.com",
      linkedin: "https://www.linkedin.com/in/emily/",
      bio: "Cybersecurity consultant assisting organizations in implementing robust security measures.",
      location: "Toronto, Canada",
      skills: ["Security Strategy", "Compliance Management", "Security Awareness"],
      rating: 2.7
    },
  ];
  return (
    <div>
      <div className='headerSection'>
        <Header label="HiringScreen" />
      </div>
      {
        onDevelopment ?
          <InDevelopment />

          :
          <>
            {/* Body Section */}
            <div className='bodySectionFilter'>
              <div className='filterSection'>
                <FilterCard height={300} items={{
                  title: "Categories",
                  filterCategories: ["Ethical Hacker",
                    "Penetration Tester", "Incident Responder",
                    "Forensic Analyst", "Cybersecurity Consultant",
                    "Security Researcher"]
                }} />
                <FilterCard height={300}
                  items={{
                    title: "Budget",
                    filterCategories: ["Value",
                      "Mid-Range", "High-End",
                      "Custom"]
                  }}
                />
                <FilterCard height={300}
                  items={{
                    title: "DeliveryTime",
                    filterCategories: ["Express 24H",
                      "Upto 3 days", "Up to 7 days",
                      "Anytime"]
                  }}
                />
              </div>
              <div className='listSecton'>
                <div>
                  <FlatList

                    items={CyberExperts}
                  />
                </div>
              </div>
            </div>
          </>
      }

    </div>
  );
};

export default HiringDev;