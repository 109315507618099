import logo from './logo.svg';
import './App.css';
import Card from './components/namecard';
import HomePage from './screens/homePage';

// Importing
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HiringDev from './screens/HiringDev';
import ExploreOppturnities from './screens/exploreOppturnities';
import ProfileScreen from './screens/profileScreen';
import JobDiscription from './screens/JobDiscription';
import CreateYourProfile from './screens/createyourProfile';
import OwnProfileScreen from './screens/ProfileScreens/ownProfileScreen';
import CompanyRegistration from './screens/createCompanyprofile';
import WhoisRegistringScreen from './screens/whoisRegistringScreen';
import CompanyProfile from './screens/ProfileScreens/companyProfileScreen';
import ConsultingScreen from './screens/consultInfo';
import ThreatComplaint from './screens/threatSubmitScreen/threatCompalint';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomePage />}></Route>
        <Route exact path='/hiringDev' element={<HiringDev />}></Route>
        <Route exact path='/createProfile' element={<CreateYourProfile />}></Route>
        <Route exact path='/whoisRegistringScreen' element={<WhoisRegistringScreen />}></Route>
        <Route exact path='/consultingScreen' element={<ConsultingScreen />}></Route>
        <Route exact path='/threatComplaint/:id' element={<ThreatComplaint />}></Route>
        <Route exact path='/createCompanyProfile' element={<CompanyRegistration />}></Route>
        <Route exact path='/exploreOppturnities' element={<ExploreOppturnities />}></Route>
        <Route exact path='/profile/:id' element={<ProfileScreen />}></Route>
        <Route exact path='/jobDiscription/:id' element={<JobDiscription />}></Route>
        <Route exact path='/companyProfileScreen/:id' element={<CompanyProfile />}></Route>
        <Route exact path='/ownProfileScreen/:id' element={<OwnProfileScreen />}></Route>
      </Routes>


      </BrowserRouter>
  );
}

export default App;
