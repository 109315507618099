import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';  // Import Autocomplete
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';

const educationOptions = ["High School", "Associate's Degree", "Bachelor's Degree", "Master's Degree", "Doctorate"];
const programmingLanguagesOptions = ["JavaScript", "Python", "Java", "C++", "Ruby", "PHP"];
const skillsOptions = ["Test1", "Test2"];
const CreateYourProfile = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    Linkedin: '',
    City: '',
    Country: '',
    EducationLevel: '',
    ProgrammingLanguages: [],
    Skills: [],
    Bio: '',
    resume: null,
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    {
      label: 'Personal Information :',
      fields: ['FirstName', 'LastName', 'Email', 'PhoneNumber', 'Linkedin'],
    },
    {
      label: 'Location :',
      fields: ['City', 'Country'],
    },
    {
      label: 'Education & Programming :',
      fields: ['ProgrammingLanguages', 'Skills'],
    },
    {
      label: ' Bio, Resume, Education :',
      fields: ['EducationLevel', 'Bio', 'resume'],
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSkillsChange = (event, newValue) => {
    setFormData({ ...formData, Skills: newValue });
  };

  const handleProgrammingLanguagesChange = (event, newValue) => {
    setFormData({ ...formData, ProgrammingLanguages: newValue });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1);
    } else {
      console.log(formData);
      navigate('/ownProfileScreen/1');
      // Handle the final form submission, e.g., send data to an API or save it locally
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
      setFormData({ ...formData, resume: null })
    }
  };

  const validateForm = () => {
    console.log(formData.resume)
    setIsFormValid(
      steps[currentStep - 1].fields.every((field) => formData[field] !== '') &&
      (formData.resume !== null || currentStep !== 4)
    );
  };

  useEffect(() => {
    validateForm();
  }, [formData, currentStep]);

  const textFieldStyle = { margin: "1.8%", borderRadius: 6, fontFamily: "League Spartan", };
  const multiselecttextField = { marginTop: "4.8%", width: 370, borderRadius: 10, fontFamily: "League Spartan", }
  const labelStyle = { fontFamily: "League Spartan" }
  const buttonStyle = { margin: '1.8%', borderRadius: 6, fontFamily: 'League Spartan', color: 'black', backgroundColor: 'hsl(48, 26%, 93%)' };
  return (
    <div>
      <div className="headerSection">
        <Header label="ProfileCreation" />
      </div>
      <div className="bodySectionFilter5">
        <Card style={{ maxWidth: '100%', margin: '0 auto', fontFamily: 'League Spartan', marginTop: '10px', width: '50%', }}>
          <CardContent>
            <h2>Create Your Profile</h2>
            <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px' }}>
              {steps.map((step, index) => (
                currentStep === index + 1 && (
                  <div key={index}>
                    <h3>{step.label}</h3>
                    {step.fields.map((field) => (
                      field === 'Skills' ? (
                        <Autocomplete
                          key={field}
                          style={multiselecttextField}
                          multiple
                          id="skills"
                          options={skillsOptions}
                          value={formData.Skills}
                          onChange={handleSkillsChange}
                          renderInput={(params) => (
                            <TextField
                              placeholderStyle={labelStyle}
                              labelStyle={labelStyle}
                              {...params}
                              variant="outlined"
                              label="Skills"
                              InputLabelProps={{ style: { fontFamily: 'League Spartan' } }}
                              fullWidth
                            />
                          )}
                        />
                      ) : field === 'ProgrammingLanguages' ? (
                        <Autocomplete
                          key={field}
                          style={multiselecttextField}

                          multiple
                          id="ProgrammingLanguages"
                          options={programmingLanguagesOptions}
                          value={formData.ProgrammingLanguages}
                          onChange={handleProgrammingLanguagesChange}
                          renderInput={(params) => (
                            <TextField
                              placeholderStyle={labelStyle}
                              {...params}
                              variant="outlined"
                              label="Programming Languages"
                              fullWidth
                              InputLabelProps={{ style: { fontFamily: 'League Spartan' } }}
                            />
                          )}
                        />
                      ) : (
                        <TextField
                          key={field}
                          labelStyle={labelStyle}
                          style={textFieldStyle}
                          label={field === 'resume' ? 'Resume' : field}
                          name={field}
                          value={formData[field]}
                          onChange={handleChange}
                          fullWidth
                          required
                          onBlur={validateForm}
                          type={field === 'email' ? 'email' : 'text'}
                          multiline={field === 'Bio'}
                          type={field === 'resume' ? 'file' : 'text'}
                          InputLabelProps={
                            field === 'resume'
                              ? { shrink: true, style: { fontFamily: 'League Spartan', fontSize: 20, } }
                              : { style: { fontFamily: 'League Spartan' } }
                          }
                          InputProps={
                            field === 'resume'
                              ? { style: textFieldStyle, input: { display: 'none' } }
                              : { style: textFieldStyle }
                          }
                        />
                      )
                    ))}
                  </div>
                )
              ))}
              <div style={{ display: 'flex', flexDirection: 'row', width: '60%', marginTop: '10%', justifyContent: 'space-between' }}>
                <Button
                  onClick={handleBack}
                  variant="contained"
                  style={buttonStyle}
                  disabled={currentStep === 1}>
                  Back
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={buttonStyle}
                  disabled={!isFormValid}>
                  {currentStep < steps.length ? 'Next' : 'Submit'}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CreateYourProfile;
