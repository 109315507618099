import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Header from '../components/Header';
import { Images } from '../constants';
import { useNavigate } from 'react-router-dom';

const cardStyle = {
    width: '40%',
    display: 'flex',
    marginLeft: 30,
    height: 300,
    fontFamily: 'League Spartan',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'hsl(48, 26%, 93%)'
};

const spacingStyle = { marginBottom: '20px' };

const WhoisRegistringScreen = () => {
    const navigate = useNavigate();
    const handleRef = (param) => {
        // Do something when the card is clicked
        navigate(param);
    }
    return (
        <div>
            <div className="headerSection">
                <Header label="whoisRegistringScreen" />
            </div>
            <div className="bodySectionFilter5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <Card onClick={() => handleRef(`/createProfile`)} variant="elevation" elevation={4} style={cardStyle}>
                    <CardContent>
                        <h2 style={{ color: 'black' }}>Are you an individual ?</h2>
                        {/* <div >
                            <img style={{ width: 100, height: 100 }} src={Images.bonguheader} alt="Logo" />
                        </div> */}
                    </CardContent>
                </Card>
                <div style={spacingStyle} />
                <Card onClick={() => handleRef(`/createCompanyProfile`)} variant="elevation" elevation={4} style={cardStyle}>
                    <CardContent>
                        <h2 style={{ color: 'black' }}>Are you a Company ?</h2>
                        {/* <div >
                            <img style={{ width: 100, height: 100 }} src={Images.bonguheader2} alt="Logo" />
                        </div> */}
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default WhoisRegistringScreen;
