import React from 'react';
import { Images } from '../constants';
import './component.css'
import "@fontsource/league-spartan";
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
const Header = (props) => {
    const { label } = props;
    const navigate = useNavigate();
    return (
        <header className="header">
            <div className="logo" onClick={() => navigate('/')}>
                <p style={{
                    fontFamily: "League Spartan",
                    color: 'black',
                    fontWeight: 'bold',
                    fontSize: '30px'
                }}>bongu</p>
            </div>
            {
                label === "HiringScreen" || label === "ProfileCreation" || label === "whoisRegistringScreen" || label === "ConsultingScreen" ?
                    <></> :
                    <nav className="nav">
                        <ul>
                            <li style={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
                                <a href="/consultingScreen" style={{
                                    fontFamily: "League Spartan",
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    marginRight: '20px',
                                }}>
                                    Consult an Expert ?
                                </a>
                                {/* <a href="/whoisRegistringScreen" style={{
                                    fontFamily: "League Spartan",
                                    color: 'black',
                                    fontWeight: 'bold',
                                    fontSize: '25px',
                                }}>
                                  <PersonIcon  />
                                </a> */}

                            </li>
                        </ul>
                    </nav>
            }

        </header>
    );
};

export default Header;