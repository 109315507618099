import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        flexDirection: 'row',
        width: '80%', // Make the card narrower
        padding: '16px', // Reduce padding for a smaller appearance
        margin: '20px',
        borderRadius: '70px', // Add more rounded corners
        transition: 'transform 0.2s', // Add a transition effect
        '&:hover': {
            transform: 'scale(1.02)', // Scale up on hover
        },
    },
    content: {
        flex: 1,
    },
    title: {
        marginBottom: '4px',
        color: 'black',
    },
    company: {
        marginBottom: '4px',
        color: 'black',
    },
    location: {
        fontSize: '0.9rem',
        color: '#555',
        marginBottom: '8px',
    },
    description: {
        fontSize: '0.9rem',
        color: '#333',
    },
    applyButton: {
        marginLeft: 'auto',
        background: '#00a82d',
        color: '#fff',
        '&:hover': {
            background: '#009925',
        },
    },
    logo: {
        width: '60px',
        height: '60px',
        marginLeft: 'auto',
        borderRadius: '40px'
    },
});


const JobCard = ({ item }) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const { id, title, company, location, description, logoUrl } = item;

    const handleRef = () => {
        console.log("Calleds=====>")
        // Do something when the card is clicked
        navigate(`/jobDiscription/${id}`);
    }
    return (
        <Card variant="elevation" elevation={4} className={classes.card} style={{ borderRadius: 40 }}>

            <div className={classes.content}>
                <CardContent style={{ boxShadow: "none" }}>
                    <CardActionArea onClick={() => handleRef()} style={{ background: 'transparent' }}>
                        <Typography style={{
                            fontSize: 22,
                            fontFamily: "League Spartan", fontWeight: 'bold'
                        }} component="div" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 18,
                                fontFamily: "League Spartan", fontWeight: 'bold'
                            }}
                            className={classes.company}>
                            {company}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 14,
                                fontFamily: "League Spartan", fontWeight: 'bold'
                            }} className={classes.location}>
                            {location}
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 16,
                                fontFamily: "League Spartan",
                            }}
                            className={classes.description}>
                            {description}
                        </Typography>
                    </CardActionArea>
                </CardContent>
            </div>
            <img src={logoUrl} alt="Company Logo" className={classes.logo} />

        </Card>
    );
};

export default JobCard;
