import React, { useEffect } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Header from '../components/Header';
import { FaEnvelope, FaLinkedin, FaStar } from 'react-icons/fa';
import './screen.css'
import ImageCarousel from '../components/ImageCarousel';




const ProfileScreen = (props) => {
    const params = useParams();
    const { id } = params
    const CyberExperts = [
        {
            id: "1",
            name: "David",
            occupation: "Ethical Hacker",
            profileImage: "https://source.unsplash.com/random/200x200",
            email: "david@example.com",
            linkedin: "https://www.linkedin.com/in/david/",
            bio: "Experienced ethical hacker with a passion for cybersecurity and data protection.",
            location: "New York, USA",
            skills: ["Penetration Testing", "Web Application Security", "Network Security"],
            rating: 4.8,
            certifications: ["CEH", "OSCP", "CISSP", "CISM", "GIAC"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
            experience:{
                Title : "Cybersecurity and Data Protection Expert",
                Discription: "With over 14 years of experience in the Cybersecurity industry, I have a proven track record of success in both hands-on and managerial roles. I am passionate about creating a safer world where businesses are protected from Cybercriminals, and I have worked tirelessly to make that dream a reality. My expertise has been recognized through the approval of six patents in the United States, and I am always seeking new ways to innovate and improve upon existing solutions. I am committed to helping businesses protect themselves and their customers from the ever-evolving threat of Cyberattacks."
            }
        },
        {
            id: "2",
            name: "Sarah",
            occupation: "Penetration Tester",
            profileImage: "https://source.unsplash.com/random/200x200",
            email: "sarah@example.com",
            linkedin: "https://www.linkedin.com/in/sarah/",
            bio: "Specializing in penetration testing and identifying vulnerabilities in systems.",
            location: "London, UK",
            skills: ["Vulnerability Assessment", "Social Engineering", "Wireless Security"],
            rating: 3.6,
            certifications: ["CEH", "OSCP", "CISSP", "CISM", "GIAC"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
            experience:{
                Title : "Cybersecurity and Data Protection Expert",
                Discription: "With over 14 years of experience in the Cybersecurity industry, I have a proven track record of success in both hands-on and managerial roles. I am passionate about creating a safer world where businesses are protected from Cybercriminals, and I have worked tirelessly to make that dream a reality. My expertise has been recognized through the approval of six patents in the United States, and I am always seeking new ways to innovate and improve upon existing solutions. I am committed to helping businesses protect themselves and their customers from the ever-evolving threat of Cyberattacks."
            }
        },
        {
            id: "3",
            name: "Michael",
            occupation: "Security Analyst",
            profileImage: "https://picsum.photos/400/402",
            email: "michael@example.com",
            linkedin: "https://www.linkedin.com/in/michael/",
            bio: "Dedicated security analyst with a focus on threat detection and incident response.",
            location: "San Francisco, USA",
            skills: ["Security Monitoring", "Incident Response", "Malware Analysis"],
            rating: 4.9,
            certifications: ["CEH", "OSCP", "CISSP", "CISM", "GIAC"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
            experience:{
                Title : "Cybersecurity and Data Protection Expert",
                Discription: "With over 14 years of experience in the Cybersecurity industry, I have a proven track record of success in both hands-on and managerial roles. I am passionate about creating a safer world where businesses are protected from Cybercriminals, and I have worked tirelessly to make that dream a reality. My expertise has been recognized through the approval of six patents in the United States, and I am always seeking new ways to innovate and improve upon existing solutions. I am committed to helping businesses protect themselves and their customers from the ever-evolving threat of Cyberattacks."
            }
        },
        {
            id: "4",
            name: "Emily",
            occupation: "Cybersecurity Consultant",
            profileImage: "https://picsum.photos/200/303",
            email: "emily@example.com",
            linkedin: "https://www.linkedin.com/in/emily/",
            bio: "Cybersecurity consultant assisting organizations in implementing robust security measures.",
            location: "Toronto, Canada",
            skills: ["Security Strategy", "Compliance Management", "Security Awareness"],
            rating: 2.7,
            certifications: ["CEH", "OSCP", "CISSP", "CISM", "GIAC"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
            experience:{
                Title : "Cybersecurity and Data Protection Expert",
                Discription: "With over 14 years of experience in the Cybersecurity industry, I have a proven track record of success in both hands-on and managerial roles. I am passionate about creating a safer world where businesses are protected from Cybercriminals, and I have worked tirelessly to make that dream a reality. My expertise has been recognized through the approval of six patents in the United States, and I am always seeking new ways to innovate and improve upon existing solutions. I am committed to helping businesses protect themselves and their customers from the ever-evolving threat of Cyberattacks."
            }
        },
        {
            name: "James",
            occupation: "Incident Responder",
            profileImage: "https://picsum.photos/200/304",
            email: "james@example.com",
            linkedin: "https://www.linkedin.com/in/james/",
            bio: "Experienced incident responder with a focus on handling and mitigating security incidents.",
            location: "Los Angeles, USA",
            skills: ["Incident Handling", "Forensics", "Threat Intelligence"],
            rating: 4.5,
            certifications: ["CPT", "CASM", "OSCE"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
            experience:{
                Title : "Cybersecurity and Data Protection Expert",
                Discription: "With over 14 years of experience in the Cybersecurity industry, I have a proven track record of success in both hands-on and managerial roles. I am passionate about creating a safer world where businesses are protected from Cybercriminals, and I have worked tirelessly to make that dream a reality. My expertise has been recognized through the approval of six patents in the United States, and I am always seeking new ways to innovate and improve upon existing solutions. I am committed to helping businesses protect themselves and their customers from the ever-evolving threat of Cyberattacks."
            }
        },
        {
            name: "Sophia",
            occupation: "Security Researcher",
            profileImage: "https://picsum.photos/200/305",
            email: "sophia@example.com",
            linkedin: "https://www.linkedin.com/in/sophia/",
            bio: "Passionate security researcher uncovering vulnerabilities and improving security measures.",
            location: "Berlin, Germany",
            skills: ["Security Research", "Exploit Development", "Cryptanalysis"],
            rating: 4.6,
            certifications: ["CPT", "CASM", "OSCE"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],

        },
        {
            name: "Daniel",
            occupation: "Forensic Analyst",
            profileImage: "https://picsum.photos/200/306",
            email: "daniel@example.com",
            linkedin: "https://www.linkedin.com/in/daniel/",
            bio: "Forensic analyst specializing in digital investigations and evidence analysis.",
            location: "Sydney, Australia",
            skills: ["Digital Forensics", "Forensic Tools", "Evidence Preservation"],
            rating: 4.8,
            certifications: ["CPT", "CASM", "OSCE"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
        },
        {
            name: "Olivia",
            occupation: "Threat Intelligence Analyst",
            profileImage: "https://picsum.photos/200/307",
            email: "olivia@example.com",
            linkedin: "https://www.linkedin.com/in/olivia/",
            bio: "Analyzing and understanding cyber threats to proactively protect organizations.",
            location: "Singapore",
            skills: ["Threat Analysis", "Cyber Threat Intelligence", "Cyber Threat Modeling"],
            rating: 4.9,
            certifications: ["CPT", "CASM", "OSCE"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
        },
        // Add more experts here
        {
            name: "John",
            occupation: "Security Engineer",
            profileImage: "https://picsum.photos/200/308",
            email: "john@example.com",
            linkedin: "https://www.linkedin.com/in/john/",
            bio: "Security engineer focusing on building secure systems and applications.",
            location: "Seattle, USA",
            skills: ["Secure Coding", "Security Architecture", "DevSecOps"],
            rating: 4.7,
            certifications: ["CPT", "CASM", "OSCE"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
        },
        {
            name: "Lily",
            occupation: "Security Compliance Analyst",
            profileImage: "https://picsum.photos/200/309",
            email: "lily@example.com",
            linkedin: "https://www.linkedin.com/in/lily/",
            bio: "Ensuring organizational compliance with security standards and regulations.",
            location: "Washington D.C., USA",
            skills: ["Compliance Audits", "Policy Development", "Risk Assessment"],
            rating: 4.5,
            certifications: ["CPT", "CASM", "OSCE"],
            Programinglanguages: ["Java", "JavaScript", "SQL", "Python"],
        }
    ];
    let profileData = CyberExperts.filter((item) => item.id === id)[0];
    // resndering Profile
    const SkillBubble = ({ skill }) => {
        return (
            <div className="skill-bubble">
                {skill}
            </div>
        );
    };
    const renderRatingStars = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <FaStar
                    key={i}
                    size={30}
                    className={`star-icon ${i < Math.floor(profileData.rating) ? 'filled' : ''}`}
                />
            );
        }
        return stars;
    };
    return (
        <div  className="profile-screen-container">
            <div className='headerSection'>
                <Header label="HiringScreen" />
            </div>
            {/* Body Section */}
            <div className='bodySectionFilter'>
                <div className='profileSection'>
                    <div class="profileImage">
                        <img src={profileData.profileImage} alt="Profile Image" />
                        <div className="profile-info">
                            <h2 className="profile-name">{profileData.name}</h2>
                            <p><FaEnvelope /> <strong>Email:</strong> {profileData.email}</p>
                            <p><FaLinkedin /> <strong>LinkedIn:</strong> <a href={profileData.linkedin} target="_blank" rel="noopener noreferrer">{profileData.name}'s LinkedIn Profile</a></p>
                            <p><strong>Bio:</strong> {profileData.bio}</p>
                            <p><strong>Location:</strong> {profileData.location}</p>
                        </div>
                    </div>
                    <div className='profileImage'>
                        
                        <div className="skills-section">
                            <strong>Skills :</strong>
                            <div className="skill-bubbles">
                                {profileData?.skills?.map((skill, index) => (
                                    <SkillBubble key={index} skill={skill} />
                                ))}
                            </div>

                        </div>
                        {<br></br>}
                        <div style={{
                            paddingTop: 10
                        }}>
                            <strong>Rating :</strong>
                            <div className="rating-stars">
                                {renderRatingStars()}
                            </div>
                        </div>
                        {<br></br>}
                        <strong>Education Level :</strong>
                        <div className="skill-bubbles">
                            {profileData?.certifications?.map((skill, index) => (
                                <SkillBubble key={index} skill={skill} />
                            ))}
                        </div>
                    </div>
                    <div class="profileImage">
                        <strong>Certification :</strong>
                        <div className="skill-bubbles">
                            {profileData?.certifications?.map((skill, index) => (
                                <SkillBubble key={index} skill={skill} />
                            ))}
                        </div>
                        {<br></br>}
                        <strong>Programing Languages :</strong>
                        <div className="skill-bubbles">
                            {profileData?.Programinglanguages?.map((skill, index) => (
                                <SkillBubble key={index} skill={skill} />
                            ))}
                        </div>
                    </div>

                </div>
                <div className='discriptionSection'>
                
                    <div className='discriptionText'>
                        <h3>{profileData.experience.Title}</h3>
                        <p style={{fontSize:18}}>
                        {profileData?.experience?.Discription}
                        </p>
                    </div>
                    <div className='skillscontainer'>
                        <div className='Skills'>   
                           <h3>Work History</h3>
                           <ImageCarousel/>
                        </div>
                        <div className='buttonsContainer'>
                            <div className='buttons'>
                             <p>Contact Me</p>
                            </div>
                            <div className='buttons'>
                            <p>Message Me</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileScreen;